import { coreApi as api } from './coreApi';
export const addTagTypes = ['owner'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getOwnerAssignment: build.query<
                GetOwnerAssignmentApiResponse,
                GetOwnerAssignmentApiArg
            >({
                query: (queryArg) => ({ url: `/owner/assignments/${queryArg.assignmentId}/` }),
                providesTags: ['owner'],
            }),
            getOwnerAssignmentWelcomeGuideDetails: build.query<
                GetOwnerAssignmentWelcomeGuideDetailsApiResponse,
                GetOwnerAssignmentWelcomeGuideDetailsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/${queryArg.assignmentId}/welcome-guide-details/`,
                }),
                providesTags: ['owner'],
            }),
            createOwnerAssignmentWelcomeGuideDetails: build.mutation<
                CreateOwnerAssignmentWelcomeGuideDetailsApiResponse,
                CreateOwnerAssignmentWelcomeGuideDetailsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/${queryArg.assignmentId}/welcome-guide-details/`,
                    method: 'POST',
                    body: queryArg.welcomeGuideDetails,
                }),
                invalidatesTags: ['owner'],
            }),
            updateOwnerAssignmentWelcomeGuideDetails: build.mutation<
                UpdateOwnerAssignmentWelcomeGuideDetailsApiResponse,
                UpdateOwnerAssignmentWelcomeGuideDetailsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/${queryArg.assignmentId}/welcome-guide-details/`,
                    method: 'PUT',
                    body: queryArg.welcomeGuideDetails,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerAssignmentsConfirmed: build.query<
                GetOwnerAssignmentsConfirmedApiResponse,
                GetOwnerAssignmentsConfirmedApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/confirmed/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerAssignmentsConfirmedCurrent: build.query<
                GetOwnerAssignmentsConfirmedCurrentApiResponse,
                GetOwnerAssignmentsConfirmedCurrentApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/confirmed/current/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            getOwnerAssignmentsConfirmedPast: build.query<
                GetOwnerAssignmentsConfirmedPastApiResponse,
                GetOwnerAssignmentsConfirmedPastApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/confirmed/past/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerAssignmentsConfirmedUpcoming: build.query<
                GetOwnerAssignmentsConfirmedUpcomingApiResponse,
                GetOwnerAssignmentsConfirmedUpcomingApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/confirmed/upcoming/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerAssignmentsDeleted: build.query<
                GetOwnerAssignmentsDeletedApiResponse,
                GetOwnerAssignmentsDeletedApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/deleted/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerAssignmentsOpen: build.query<
                GetOwnerAssignmentsOpenApiResponse,
                GetOwnerAssignmentsOpenApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/open/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerAssignmentsUnconfirmedPast: build.query<
                GetOwnerAssignmentsUnconfirmedPastApiResponse,
                GetOwnerAssignmentsUnconfirmedPastApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/assignments/unconfirmed/past/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerConversation: build.query<
                GetOwnerConversationApiResponse,
                GetOwnerConversationApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/conversations/${queryArg.userId}/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            createOwnerConversation: build.mutation<
                CreateOwnerConversationApiResponse,
                CreateOwnerConversationApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/conversations/${queryArg.userId}/`,
                    method: 'POST',
                    body: queryArg.ownerMessage,
                }),
                invalidatesTags: ['owner'],
            }),
            updateOwnerConversation: build.mutation<
                UpdateOwnerConversationApiResponse,
                UpdateOwnerConversationApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/conversations/${queryArg.userId}/`,
                    method: 'PUT',
                    body: queryArg.ownerMessage,
                }),
                invalidatesTags: ['owner'],
            }),
            createOwnerConversationsBatch: build.mutation<
                CreateOwnerConversationsBatchApiResponse,
                CreateOwnerConversationsBatchApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/conversations/batch/`,
                    method: 'POST',
                    body: queryArg.ownerMessage,
                }),
                invalidatesTags: ['owner'],
            }),
            updateOwnerConversationsBatch: build.mutation<
                UpdateOwnerConversationsBatchApiResponse,
                UpdateOwnerConversationsBatchApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/conversations/batch/`,
                    method: 'PUT',
                    body: queryArg.ownerMessage,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerFavourites: build.query<
                GetOwnerFavouritesApiResponse,
                GetOwnerFavouritesApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/favourites/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerFavourites: build.mutation<
                CreateOwnerFavouritesApiResponse,
                CreateOwnerFavouritesApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/favourites/`,
                    method: 'POST',
                    body: queryArg.createOwnerFavourite,
                    params: { fields: queryArg.fields },
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerFavourite: build.mutation<
                DeleteOwnerFavouriteApiResponse,
                DeleteOwnerFavouriteApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/favourites/${queryArg.profileId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListings: build.query<GetOwnerListingsApiResponse, GetOwnerListingsApiArg>({
                query: (queryArg) => ({
                    url: `/owner/listings/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListings: build.mutation<
                CreateOwnerListingsApiResponse,
                CreateOwnerListingsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/`,
                    method: 'POST',
                    body: queryArg.listing,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListing: build.query<GetOwnerListingApiResponse, GetOwnerListingApiArg>({
                query: (queryArg) => ({ url: `/owner/listings/${queryArg.listingId}/` }),
                providesTags: ['owner'],
            }),
            updateOwnerListing: build.mutation<
                UpdateOwnerListingApiResponse,
                UpdateOwnerListingApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/`,
                    method: 'PUT',
                    body: queryArg.listing,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAddress: build.query<
                GetOwnerListingAddressApiResponse,
                GetOwnerListingAddressApiArg
            >({
                query: (queryArg) => ({ url: `/owner/listings/${queryArg.listingId}/address/` }),
                providesTags: ['owner'],
            }),
            createOwnerListingAddress: build.mutation<
                CreateOwnerListingAddressApiResponse,
                CreateOwnerListingAddressApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/address/`,
                    method: 'POST',
                    body: queryArg.address,
                }),
                invalidatesTags: ['owner'],
            }),
            updateOwnerListingAddress: build.mutation<
                UpdateOwnerListingAddressApiResponse,
                UpdateOwnerListingAddressApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/address/`,
                    method: 'PUT',
                    body: queryArg.address,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAmenities: build.query<
                GetOwnerListingAmenitiesApiResponse,
                GetOwnerListingAmenitiesApiArg
            >({
                query: (queryArg) => ({ url: `/owner/listings/${queryArg.listingId}/amenities/` }),
                providesTags: ['owner'],
            }),
            createOwnerListingAmenities: build.mutation<
                CreateOwnerListingAmenitiesApiResponse,
                CreateOwnerListingAmenitiesApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/amenities/`,
                    method: 'POST',
                    body: queryArg.amenities,
                }),
                invalidatesTags: ['owner'],
            }),
            updateOwnerListingAmenities: build.mutation<
                UpdateOwnerListingAmenitiesApiResponse,
                UpdateOwnerListingAmenitiesApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/amenities/`,
                    method: 'PUT',
                    body: queryArg.amenities,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentApplications: build.query<
                GetOwnerListingAssignmentApplicationsApiResponse,
                GetOwnerListingAssignmentApplicationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/${queryArg.assignmentId}/applications/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        order_by: queryArg.orderBy,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerListingAssignmentApplication: build.query<
                GetOwnerListingAssignmentApplicationApiResponse,
                GetOwnerListingAssignmentApplicationApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/${queryArg.assignmentId}/applications/${queryArg.applicationId}/`,
                }),
                providesTags: ['owner'],
            }),
            updateOwnerListingAssignmentApplication: build.mutation<
                UpdateOwnerListingAssignmentApplicationApiResponse,
                UpdateOwnerListingAssignmentApplicationApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/${queryArg.assignmentId}/applications/${queryArg.applicationId}/`,
                    method: 'PUT',
                    body: queryArg.application,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentInvitations: build.query<
                GetOwnerListingAssignmentInvitationsApiResponse,
                GetOwnerListingAssignmentInvitationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/${queryArg.assignmentId}/invitations/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        order_by: queryArg.orderBy,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingAssignmentInvitations: build.mutation<
                CreateOwnerListingAssignmentInvitationsApiResponse,
                CreateOwnerListingAssignmentInvitationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/${queryArg.assignmentId}/invitations/`,
                    method: 'POST',
                    body: queryArg.invitation,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentInvitation: build.query<
                GetOwnerListingAssignmentInvitationApiResponse,
                GetOwnerListingAssignmentInvitationApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/${queryArg.assignmentId}/invitations/${queryArg.applicationId}/`,
                }),
                providesTags: ['owner'],
            }),
            updateOwnerListingAssignmentInvitation: build.mutation<
                UpdateOwnerListingAssignmentInvitationApiResponse,
                UpdateOwnerListingAssignmentInvitationApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/${queryArg.assignmentId}/invitations/${queryArg.applicationId}/`,
                    method: 'PUT',
                    body: queryArg.invitation,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentsConfirmedCurrent: build.query<
                GetOwnerListingAssignmentsConfirmedCurrentApiResponse,
                GetOwnerListingAssignmentsConfirmedCurrentApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/current/${queryArg.assignmentId}/`,
                }),
                providesTags: ['owner'],
            }),
            getOwnerListingAssignmentsConfirmedPast: build.query<
                GetOwnerListingAssignmentsConfirmedPastApiResponse,
                GetOwnerListingAssignmentsConfirmedPastApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/past/${queryArg.assignmentId}/`,
                }),
                providesTags: ['owner'],
            }),
            updateOwnerListingAssignmentsConfirmedPastFeedback: build.mutation<
                UpdateOwnerListingAssignmentsConfirmedPastFeedbackApiResponse,
                UpdateOwnerListingAssignmentsConfirmedPastFeedbackApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/past/${queryArg.assignmentId}/feedback/`,
                    method: 'PUT',
                    body: queryArg.feedback,
                }),
                invalidatesTags: ['owner'],
            }),
            createOwnerListingAssignmentsConfirmedPastFeedbackRequest: build.mutation<
                CreateOwnerListingAssignmentsConfirmedPastFeedbackRequestApiResponse,
                CreateOwnerListingAssignmentsConfirmedPastFeedbackRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/past/${queryArg.assignmentId}/feedback-request/`,
                    method: 'POST',
                    body: queryArg.feedbackRequest,
                }),
                invalidatesTags: ['owner'],
            }),
            createOwnerListingAssignmentsConfirmedPastReview: build.mutation<
                CreateOwnerListingAssignmentsConfirmedPastReviewApiResponse,
                CreateOwnerListingAssignmentsConfirmedPastReviewApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/past/${queryArg.assignmentId}/review/`,
                    method: 'POST',
                    body: queryArg.review,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentsConfirmedUpcoming: build.query<
                GetOwnerListingAssignmentsConfirmedUpcomingApiResponse,
                GetOwnerListingAssignmentsConfirmedUpcomingApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/upcoming/${queryArg.assignmentId}/`,
                }),
                providesTags: ['owner'],
            }),
            updateOwnerListingAssignmentsConfirmedUpcoming: build.mutation<
                UpdateOwnerListingAssignmentsConfirmedUpcomingApiResponse,
                UpdateOwnerListingAssignmentsConfirmedUpcomingApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/upcoming/${queryArg.assignmentId}/`,
                    method: 'PUT',
                    body: queryArg.upcomingAssignment,
                }),
                invalidatesTags: ['owner'],
            }),
            createOwnerListingAssignmentsConfirmedUpcomingBatchMessages: build.mutation<
                CreateOwnerListingAssignmentsConfirmedUpcomingBatchMessagesApiResponse,
                CreateOwnerListingAssignmentsConfirmedUpcomingBatchMessagesApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/confirmed/upcoming/${queryArg.assignmentId}/batch-messages/`,
                    method: 'POST',
                    body: queryArg.batchMessage,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentsFuture: build.query<
                GetOwnerListingAssignmentsFutureApiResponse,
                GetOwnerListingAssignmentsFutureApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/future/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            getOwnerListingAssignmentsOpen: build.query<
                GetOwnerListingAssignmentsOpenApiResponse,
                GetOwnerListingAssignmentsOpenApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/open/`,
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingAssignmentsOpen: build.mutation<
                CreateOwnerListingAssignmentsOpenApiResponse,
                CreateOwnerListingAssignmentsOpenApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/open/`,
                    method: 'POST',
                    body: queryArg.openAssignment,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentOpen: build.query<
                GetOwnerListingAssignmentOpenApiResponse,
                GetOwnerListingAssignmentOpenApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/open/${queryArg.assignmentId}/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            updateOwnerListingAssignmentsOpen: build.mutation<
                UpdateOwnerListingAssignmentsOpenApiResponse,
                UpdateOwnerListingAssignmentsOpenApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/open/${queryArg.assignmentId}/`,
                    method: 'PUT',
                    body: queryArg.openAssignment,
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerListingAssignmentsOpen: build.mutation<
                DeleteOwnerListingAssignmentsOpenApiResponse,
                DeleteOwnerListingAssignmentsOpenApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/open/${queryArg.assignmentId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            createOwnerListingAssignmentsOpenBoost: build.mutation<
                CreateOwnerListingAssignmentsOpenBoostApiResponse,
                CreateOwnerListingAssignmentsOpenBoostApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/open/${queryArg.assignmentId}/boost/`,
                    method: 'POST',
                    body: queryArg.openAssignment,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingAssignmentsOpenSitter: build.query<
                GetOwnerListingAssignmentsOpenSitterApiResponse,
                GetOwnerListingAssignmentsOpenSitterApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/assignments/open/sitter/${queryArg.profileId}/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingInvitationsBatch: build.mutation<
                CreateOwnerListingInvitationsBatchApiResponse,
                CreateOwnerListingInvitationsBatchApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/invitations/batch/`,
                    method: 'POST',
                    body: queryArg.invitation,
                    params: { fields: queryArg.fields },
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingPets: build.query<
                GetOwnerListingPetsApiResponse,
                GetOwnerListingPetsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingPets: build.mutation<
                CreateOwnerListingPetsApiResponse,
                CreateOwnerListingPetsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/`,
                    method: 'POST',
                    body: queryArg.pet,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingPet: build.query<
                GetOwnerListingPetApiResponse,
                GetOwnerListingPetApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/${queryArg.petId}/`,
                }),
                providesTags: ['owner'],
            }),
            updateOwnerListingPet: build.mutation<
                UpdateOwnerListingPetApiResponse,
                UpdateOwnerListingPetApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/${queryArg.petId}/`,
                    method: 'PUT',
                    body: queryArg.pet,
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerListingPet: build.mutation<
                DeleteOwnerListingPetApiResponse,
                DeleteOwnerListingPetApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/${queryArg.petId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingPetPhotos: build.query<
                GetOwnerListingPetPhotosApiResponse,
                GetOwnerListingPetPhotosApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/${queryArg.petId}/photos/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingPetPhoto: build.mutation<
                CreateOwnerListingPetPhotoApiResponse,
                CreateOwnerListingPetPhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/${queryArg.petId}/photos/${queryArg.photoId}/`,
                    method: 'POST',
                    body: queryArg.petPhoto,
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerListingPetPhoto: build.mutation<
                DeleteOwnerListingPetPhotoApiResponse,
                DeleteOwnerListingPetPhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/pets/${queryArg.petId}/photos/${queryArg.photoId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingPhotos: build.query<
                GetOwnerListingPhotosApiResponse,
                GetOwnerListingPhotosApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/photos/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingPhoto: build.mutation<
                CreateOwnerListingPhotoApiResponse,
                CreateOwnerListingPhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/photos/${queryArg.photoId}/`,
                    method: 'POST',
                    body: queryArg.listingPhoto,
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerListingPhoto: build.mutation<
                DeleteOwnerListingPhotoApiResponse,
                DeleteOwnerListingPhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/photos/${queryArg.photoId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingPlacesOfInterest: build.query<
                GetOwnerListingPlacesOfInterestApiResponse,
                GetOwnerListingPlacesOfInterestApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingPlacesOfInterest: build.mutation<
                CreateOwnerListingPlacesOfInterestApiResponse,
                CreateOwnerListingPlacesOfInterestApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/`,
                    method: 'POST',
                    body: queryArg.placeOfInterest,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingPlaceOfInterest: build.query<
                GetOwnerListingPlaceOfInterestApiResponse,
                GetOwnerListingPlaceOfInterestApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/${queryArg.placeOfInterestId}/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            updateOwnerListingPlacesOfInterest: build.mutation<
                UpdateOwnerListingPlacesOfInterestApiResponse,
                UpdateOwnerListingPlacesOfInterestApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/${queryArg.placeOfInterestId}/`,
                    method: 'PUT',
                    body: queryArg.placeOfInterest,
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerListingPlacesOfInterest: build.mutation<
                DeleteOwnerListingPlacesOfInterestApiResponse,
                DeleteOwnerListingPlacesOfInterestApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/${queryArg.placeOfInterestId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingPlacesOfInterestPhotos: build.query<
                GetOwnerListingPlacesOfInterestPhotosApiResponse,
                GetOwnerListingPlacesOfInterestPhotosApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/${queryArg.placeOfInterestId}/photos/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingPlacesOfInterestPhoto: build.mutation<
                CreateOwnerListingPlacesOfInterestPhotoApiResponse,
                CreateOwnerListingPlacesOfInterestPhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/${queryArg.placeOfInterestId}/photos/${queryArg.photoId}/`,
                    method: 'POST',
                    body: queryArg.placeOfInterestPhoto,
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerListingPlacesOfInterestPhoto: build.mutation<
                DeleteOwnerListingPlacesOfInterestPhotoApiResponse,
                DeleteOwnerListingPlacesOfInterestPhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/places-of-interest/${queryArg.placeOfInterestId}/photos/${queryArg.photoId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            createOwnerListingPublish: build.mutation<
                CreateOwnerListingPublishApiResponse,
                CreateOwnerListingPublishApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/publish/`,
                    method: 'POST',
                }),
                invalidatesTags: ['owner'],
            }),
            createOwnerListingSubmissionReview: build.mutation<
                CreateOwnerListingSubmissionReviewApiResponse,
                CreateOwnerListingSubmissionReviewApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/submission/review/`,
                    method: 'POST',
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingWelcomeGuide: build.query<
                GetOwnerListingWelcomeGuideApiResponse,
                GetOwnerListingWelcomeGuideApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/welcome-guide/`,
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingWelcomeGuide: build.mutation<
                CreateOwnerListingWelcomeGuideApiResponse,
                CreateOwnerListingWelcomeGuideApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/welcome-guide/`,
                    method: 'POST',
                    body: queryArg.welcomeGuide,
                }),
                invalidatesTags: ['owner'],
            }),
            updateOwnerListingWelcomeGuide: build.mutation<
                UpdateOwnerListingWelcomeGuideApiResponse,
                UpdateOwnerListingWelcomeGuideApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/welcome-guide/`,
                    method: 'PUT',
                    body: queryArg.welcomeGuide,
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerListingWelcomeGuidePhotos: build.query<
                GetOwnerListingWelcomeGuidePhotosApiResponse,
                GetOwnerListingWelcomeGuidePhotosApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/welcome-guide/photos/`,
                    params: { fields: queryArg.fields },
                }),
                providesTags: ['owner'],
            }),
            createOwnerListingWelcomeGuidePhoto: build.mutation<
                CreateOwnerListingWelcomeGuidePhotoApiResponse,
                CreateOwnerListingWelcomeGuidePhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/welcome-guide/photos/${queryArg.photoId}/`,
                    method: 'POST',
                    body: queryArg.welcomeGuidePhoto,
                }),
                invalidatesTags: ['owner'],
            }),
            deleteOwnerListingWelcomeGuidePhoto: build.mutation<
                DeleteOwnerListingWelcomeGuidePhotoApiResponse,
                DeleteOwnerListingWelcomeGuidePhotoApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/listings/${queryArg.listingId}/welcome-guide/photos/${queryArg.photoId}/`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['owner'],
            }),
            getOwnerSitterApplications: build.query<
                GetOwnerSitterApplicationsApiResponse,
                GetOwnerSitterApplicationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/sitter/${queryArg.profileId}/applications/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        id_in: queryArg.idIn,
                        limit: queryArg.limit,
                        order_by: queryArg.orderBy,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerSitterAssignments: build.query<
                GetOwnerSitterAssignmentsApiResponse,
                GetOwnerSitterAssignmentsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/sitter/${queryArg.profileId}/assignments/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        limit: queryArg.limit,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
            getOwnerSitterInvitations: build.query<
                GetOwnerSitterInvitationsApiResponse,
                GetOwnerSitterInvitationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/owner/sitter/${queryArg.profileId}/invitations/`,
                    params: {
                        ending_before: queryArg.endingBefore,
                        fields: queryArg.fields,
                        id_in: queryArg.idIn,
                        limit: queryArg.limit,
                        order_by: queryArg.orderBy,
                        starting_after: queryArg.startingAfter,
                    },
                }),
                providesTags: ['owner'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as ownerApi };
export type GetOwnerAssignmentApiResponse = /** status 200  */ Assignment;
export type GetOwnerAssignmentApiArg = {
    assignmentId: string;
};
export type GetOwnerAssignmentWelcomeGuideDetailsApiResponse =
    /** status 200  */ WelcomeGuideDetails;
export type GetOwnerAssignmentWelcomeGuideDetailsApiArg = {
    assignmentId: string;
};
export type CreateOwnerAssignmentWelcomeGuideDetailsApiResponse =
    /** status 200  */ WelcomeGuideDetails;
export type CreateOwnerAssignmentWelcomeGuideDetailsApiArg = {
    assignmentId: string;
    welcomeGuideDetails: WelcomeGuideDetails;
};
export type UpdateOwnerAssignmentWelcomeGuideDetailsApiResponse =
    /** status 200  */ WelcomeGuideDetails;
export type UpdateOwnerAssignmentWelcomeGuideDetailsApiArg = {
    assignmentId: string;
    welcomeGuideDetails: WelcomeGuideDetails;
};
export type GetOwnerAssignmentsConfirmedApiResponse = /** status 200  */ ConfirmedAssignments;
export type GetOwnerAssignmentsConfirmedApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerAssignmentsConfirmedCurrentApiResponse = /** status 200  */ CurrentAssignment[];
export type GetOwnerAssignmentsConfirmedCurrentApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
};
export type GetOwnerAssignmentsConfirmedPastApiResponse = /** status 200  */ PaginatedResponse6;
export type GetOwnerAssignmentsConfirmedPastApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerAssignmentsConfirmedUpcomingApiResponse = /** status 200  */ PaginatedResponse5;
export type GetOwnerAssignmentsConfirmedUpcomingApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerAssignmentsDeletedApiResponse = /** status 200  */ PaginatedResponse8;
export type GetOwnerAssignmentsDeletedApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerAssignmentsOpenApiResponse = /** status 200  */ PaginatedResponse9;
export type GetOwnerAssignmentsOpenApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerAssignmentsUnconfirmedPastApiResponse = /** status 200  */ PaginatedResponse10;
export type GetOwnerAssignmentsUnconfirmedPastApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerConversationApiResponse = /** status 200  */ PaginatedResponse11;
export type GetOwnerConversationApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
    userId: string;
};
export type CreateOwnerConversationApiResponse = /** status 200  */ OwnerMessage;
export type CreateOwnerConversationApiArg = {
    userId: string;
    ownerMessage: OwnerMessage;
};
export type UpdateOwnerConversationApiResponse = /** status 200  */ OwnerMessage;
export type UpdateOwnerConversationApiArg = {
    userId: string;
    ownerMessage: OwnerMessage;
};
export type CreateOwnerConversationsBatchApiResponse = /** status 200  */ OwnerMessage;
export type CreateOwnerConversationsBatchApiArg = {
    ownerMessage: OwnerMessage;
};
export type UpdateOwnerConversationsBatchApiResponse = /** status 200  */ OwnerMessage;
export type UpdateOwnerConversationsBatchApiArg = {
    ownerMessage: OwnerMessage;
};
export type GetOwnerFavouritesApiResponse = /** status 200  */ number[];
export type GetOwnerFavouritesApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
};
export type CreateOwnerFavouritesApiResponse = unknown;
export type CreateOwnerFavouritesApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    createOwnerFavourite: CreateOwnerFavourite;
};
export type DeleteOwnerFavouriteApiResponse = unknown;
export type DeleteOwnerFavouriteApiArg = {
    profileId: string;
};
export type GetOwnerListingsApiResponse = /** status 200  */ Listing[];
export type GetOwnerListingsApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
};
export type CreateOwnerListingsApiResponse = /** status 200  */ Listing;
export type CreateOwnerListingsApiArg = {
    listing: Listing;
};
export type GetOwnerListingApiResponse = /** status 200  */ Listing;
export type GetOwnerListingApiArg = {
    listingId: string;
};
export type UpdateOwnerListingApiResponse = /** status 200  */ Listing;
export type UpdateOwnerListingApiArg = {
    listingId: string;
    listing: Listing;
};
export type GetOwnerListingAddressApiResponse = /** status 200  */ Address;
export type GetOwnerListingAddressApiArg = {
    listingId: string;
};
export type CreateOwnerListingAddressApiResponse = /** status 200  */ Address;
export type CreateOwnerListingAddressApiArg = {
    listingId: string;
    address: Address;
};
export type UpdateOwnerListingAddressApiResponse = /** status 200  */ Address;
export type UpdateOwnerListingAddressApiArg = {
    listingId: string;
    address: Address;
};
export type GetOwnerListingAmenitiesApiResponse = /** status 200  */ Amenities;
export type GetOwnerListingAmenitiesApiArg = {
    listingId: string;
};
export type CreateOwnerListingAmenitiesApiResponse = /** status 200  */ Amenities;
export type CreateOwnerListingAmenitiesApiArg = {
    listingId: string;
    amenities: Amenities;
};
export type UpdateOwnerListingAmenitiesApiResponse = /** status 200  */ Amenities;
export type UpdateOwnerListingAmenitiesApiArg = {
    listingId: string;
    amenities: Amenities;
};
export type GetOwnerListingAssignmentApplicationsApiResponse =
    /** status 200  */ PaginatedResponse12;
export type GetOwnerListingAssignmentApplicationsApiArg = {
    assignmentId: string;
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    listingId: string;
    /** Comma separated list of fields to order results by. Default: `-is_shortlisted,-is_invited`. Possible values: `is_invited,is_shortlisted`. */
    orderBy?: string;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerListingAssignmentApplicationApiResponse = /** status 200  */ Application;
export type GetOwnerListingAssignmentApplicationApiArg = {
    applicationId: string;
    assignmentId: string;
    listingId: string;
};
export type UpdateOwnerListingAssignmentApplicationApiResponse = /** status 200  */ Application;
export type UpdateOwnerListingAssignmentApplicationApiArg = {
    applicationId: string;
    assignmentId: string;
    listingId: string;
    application: Application;
};
export type GetOwnerListingAssignmentInvitationsApiResponse =
    /** status 200  */ PaginatedResponse13;
export type GetOwnerListingAssignmentInvitationsApiArg = {
    assignmentId: string;
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    listingId: string;
    /** Comma separated list of fields to order results by. Default: `-is_shortlisted,-is_invited`. Possible values: `is_invited,is_shortlisted`. */
    orderBy?: string;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type CreateOwnerListingAssignmentInvitationsApiResponse = /** status 200  */ Invitation;
export type CreateOwnerListingAssignmentInvitationsApiArg = {
    assignmentId: string;
    listingId: string;
    invitation: Invitation;
};
export type GetOwnerListingAssignmentInvitationApiResponse = /** status 200  */ Invitation;
export type GetOwnerListingAssignmentInvitationApiArg = {
    applicationId: string;
    assignmentId: string;
    listingId: string;
};
export type UpdateOwnerListingAssignmentInvitationApiResponse = /** status 200  */ Invitation;
export type UpdateOwnerListingAssignmentInvitationApiArg = {
    applicationId: string;
    assignmentId: string;
    listingId: string;
    invitation: Invitation;
};
export type GetOwnerListingAssignmentsConfirmedCurrentApiResponse =
    /** status 200  */ CurrentAssignment;
export type GetOwnerListingAssignmentsConfirmedCurrentApiArg = {
    assignmentId: string;
    listingId: string;
};
export type GetOwnerListingAssignmentsConfirmedPastApiResponse = /** status 200  */ PastAssignment;
export type GetOwnerListingAssignmentsConfirmedPastApiArg = {
    assignmentId: string;
    listingId: string;
};
export type UpdateOwnerListingAssignmentsConfirmedPastFeedbackApiResponse =
    /** status 200  */ Feedback;
export type UpdateOwnerListingAssignmentsConfirmedPastFeedbackApiArg = {
    assignmentId: string;
    listingId: string;
    feedback: Feedback;
};
export type CreateOwnerListingAssignmentsConfirmedPastFeedbackRequestApiResponse =
    /** status 200  */ FeedbackRequest;
export type CreateOwnerListingAssignmentsConfirmedPastFeedbackRequestApiArg = {
    assignmentId: string;
    listingId: string;
    feedbackRequest: FeedbackRequest;
};
export type CreateOwnerListingAssignmentsConfirmedPastReviewApiResponse = /** status 200  */ Review;
export type CreateOwnerListingAssignmentsConfirmedPastReviewApiArg = {
    assignmentId: string;
    listingId: string;
    review: Review;
};
export type GetOwnerListingAssignmentsConfirmedUpcomingApiResponse =
    /** status 200  */ UpcomingAssignment;
export type GetOwnerListingAssignmentsConfirmedUpcomingApiArg = {
    assignmentId: string;
    listingId: string;
};
export type UpdateOwnerListingAssignmentsConfirmedUpcomingApiResponse =
    /** status 200  */ UpcomingAssignment;
export type UpdateOwnerListingAssignmentsConfirmedUpcomingApiArg = {
    assignmentId: string;
    listingId: string;
    upcomingAssignment: UpcomingAssignment;
};
export type CreateOwnerListingAssignmentsConfirmedUpcomingBatchMessagesApiResponse =
    /** status 200  */ BatchMessage;
export type CreateOwnerListingAssignmentsConfirmedUpcomingBatchMessagesApiArg = {
    assignmentId: string;
    listingId: string;
    batchMessage: BatchMessage;
};
export type GetOwnerListingAssignmentsFutureApiResponse = /** status 200  */ FutureAssignment[];
export type GetOwnerListingAssignmentsFutureApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
};
export type GetOwnerListingAssignmentsOpenApiResponse = /** status 200  */ OpenAssignment;
export type GetOwnerListingAssignmentsOpenApiArg = {
    listingId: string;
};
export type CreateOwnerListingAssignmentsOpenApiResponse = /** status 200  */ OpenAssignment;
export type CreateOwnerListingAssignmentsOpenApiArg = {
    listingId: string;
    openAssignment: OpenAssignment;
};
export type GetOwnerListingAssignmentOpenApiResponse = /** status 200  */ OpenAssignment;
export type GetOwnerListingAssignmentOpenApiArg = {
    assignmentId: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
};
export type UpdateOwnerListingAssignmentsOpenApiResponse = /** status 200  */ OpenAssignment;
export type UpdateOwnerListingAssignmentsOpenApiArg = {
    assignmentId: string;
    listingId: string;
    openAssignment: OpenAssignment;
};
export type DeleteOwnerListingAssignmentsOpenApiResponse = unknown;
export type DeleteOwnerListingAssignmentsOpenApiArg = {
    assignmentId: string;
    listingId: string;
};
export type CreateOwnerListingAssignmentsOpenBoostApiResponse = /** status 200  */ OpenAssignment;
export type CreateOwnerListingAssignmentsOpenBoostApiArg = {
    assignmentId: string;
    listingId: string;
    openAssignment: OpenAssignment;
};
export type GetOwnerListingAssignmentsOpenSitterApiResponse =
    /** status 200  */ OpenAssignmentWithSitterStatus[];
export type GetOwnerListingAssignmentsOpenSitterApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
    profileId: string;
};
export type CreateOwnerListingInvitationsBatchApiResponse = /** status 200  */ Invitation[];
export type CreateOwnerListingInvitationsBatchApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
    invitation: Invitation;
};
export type GetOwnerListingPetsApiResponse = /** status 200  */ Pet[];
export type GetOwnerListingPetsApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
};
export type CreateOwnerListingPetsApiResponse = /** status 200  */ Pet;
export type CreateOwnerListingPetsApiArg = {
    listingId: string;
    pet: Pet;
};
export type GetOwnerListingPetApiResponse = /** status 200  */ Pet;
export type GetOwnerListingPetApiArg = {
    listingId: string;
    petId: string;
};
export type UpdateOwnerListingPetApiResponse = /** status 200  */ Pet;
export type UpdateOwnerListingPetApiArg = {
    listingId: string;
    petId: string;
    pet: Pet;
};
export type DeleteOwnerListingPetApiResponse = unknown;
export type DeleteOwnerListingPetApiArg = {
    listingId: string;
    petId: string;
};
export type GetOwnerListingPetPhotosApiResponse = /** status 200  */ PetPhoto[];
export type GetOwnerListingPetPhotosApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
    petId: string;
};
export type CreateOwnerListingPetPhotoApiResponse = /** status 200  */ PetPhoto;
export type CreateOwnerListingPetPhotoApiArg = {
    listingId: string;
    petId: string;
    photoId: string;
    petPhoto: PetPhoto;
};
export type DeleteOwnerListingPetPhotoApiResponse = unknown;
export type DeleteOwnerListingPetPhotoApiArg = {
    listingId: string;
    petId: string;
    photoId: string;
};
export type GetOwnerListingPhotosApiResponse = /** status 200  */ ListingPhoto[];
export type GetOwnerListingPhotosApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
};
export type CreateOwnerListingPhotoApiResponse = /** status 200  */ ListingPhoto;
export type CreateOwnerListingPhotoApiArg = {
    listingId: string;
    photoId: string;
    listingPhoto: ListingPhoto;
};
export type DeleteOwnerListingPhotoApiResponse = unknown;
export type DeleteOwnerListingPhotoApiArg = {
    listingId: string;
    photoId: string;
};
export type GetOwnerListingPlacesOfInterestApiResponse = /** status 200  */ PlaceOfInterest[];
export type GetOwnerListingPlacesOfInterestApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
};
export type CreateOwnerListingPlacesOfInterestApiResponse = /** status 200  */ PlaceOfInterest;
export type CreateOwnerListingPlacesOfInterestApiArg = {
    listingId: string;
    placeOfInterest: PlaceOfInterest;
};
export type GetOwnerListingPlaceOfInterestApiResponse = /** status 200  */ PlaceOfInterest;
export type GetOwnerListingPlaceOfInterestApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
    placeOfInterestId: string;
};
export type UpdateOwnerListingPlacesOfInterestApiResponse = /** status 200  */ PlaceOfInterest;
export type UpdateOwnerListingPlacesOfInterestApiArg = {
    listingId: string;
    placeOfInterestId: string;
    placeOfInterest: PlaceOfInterest;
};
export type DeleteOwnerListingPlacesOfInterestApiResponse = unknown;
export type DeleteOwnerListingPlacesOfInterestApiArg = {
    listingId: string;
    placeOfInterestId: string;
};
export type GetOwnerListingPlacesOfInterestPhotosApiResponse =
    /** status 200  */ PlaceOfInterestPhoto[];
export type GetOwnerListingPlacesOfInterestPhotosApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
    placeOfInterestId: string;
};
export type CreateOwnerListingPlacesOfInterestPhotoApiResponse =
    /** status 200  */ PlaceOfInterestPhoto;
export type CreateOwnerListingPlacesOfInterestPhotoApiArg = {
    listingId: string;
    photoId: string;
    placeOfInterestId: string;
    placeOfInterestPhoto: PlaceOfInterestPhoto;
};
export type DeleteOwnerListingPlacesOfInterestPhotoApiResponse = unknown;
export type DeleteOwnerListingPlacesOfInterestPhotoApiArg = {
    listingId: string;
    photoId: string;
    placeOfInterestId: string;
};
export type CreateOwnerListingPublishApiResponse = /** status 200  */ PublishListing;
export type CreateOwnerListingPublishApiArg = {
    listingId: string;
};
export type CreateOwnerListingSubmissionReviewApiResponse =
    /** status 200  */ SubmissionReviewListing;
export type CreateOwnerListingSubmissionReviewApiArg = {
    listingId: string;
};
export type GetOwnerListingWelcomeGuideApiResponse = /** status 200  */ WelcomeGuide;
export type GetOwnerListingWelcomeGuideApiArg = {
    listingId: string;
};
export type CreateOwnerListingWelcomeGuideApiResponse = /** status 200  */ WelcomeGuide;
export type CreateOwnerListingWelcomeGuideApiArg = {
    listingId: string;
    welcomeGuide: WelcomeGuide;
};
export type UpdateOwnerListingWelcomeGuideApiResponse = /** status 200  */ WelcomeGuide;
export type UpdateOwnerListingWelcomeGuideApiArg = {
    listingId: string;
    welcomeGuide: WelcomeGuide;
};
export type GetOwnerListingWelcomeGuidePhotosApiResponse = /** status 200  */ WelcomeGuidePhoto[];
export type GetOwnerListingWelcomeGuidePhotosApiArg = {
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    listingId: string;
};
export type CreateOwnerListingWelcomeGuidePhotoApiResponse = /** status 200  */ WelcomeGuidePhoto;
export type CreateOwnerListingWelcomeGuidePhotoApiArg = {
    listingId: string;
    photoId: string;
    welcomeGuidePhoto: WelcomeGuidePhoto;
};
export type DeleteOwnerListingWelcomeGuidePhotoApiResponse = unknown;
export type DeleteOwnerListingWelcomeGuidePhotoApiArg = {
    listingId: string;
    photoId: string;
};
export type GetOwnerSitterApplicationsApiResponse = /** status 200  */ PaginatedResponse14;
export type GetOwnerSitterApplicationsApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Comma separated list of `ID`s to filter results to include only objects whose `ID` is in `id_in`. */
    idIn?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** Comma separated list of fields to order results by. Default: `id`. Possible values: `date_created,assignment__start_date`. */
    orderBy?: string;
    profileId: string;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerSitterAssignmentsApiResponse = /** status 200  */ PaginatedResponse15;
export type GetOwnerSitterAssignmentsApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    profileId: string;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type GetOwnerSitterInvitationsApiResponse = /** status 200  */ PaginatedResponse16;
export type GetOwnerSitterInvitationsApiArg = {
    /** `ID` of the object next page should end before. Using this parameter will also reverse the order of results. Cannot be used in conjunction with `starting_after`. */
    endingBefore?: string;
    /** Comma separated list of top level fields the response object(s) should contain. For possible fields, see model. Note that `ID` is always included. Requesting only certain fields of nested objects is not yet supported. */
    fields?: string;
    /** Comma separated list of `ID`s to filter results to include only objects whose `ID` is in `id_in`. */
    idIn?: string;
    /** Number of items per page. Default is 20. Max page size is 100. */
    limit?: number;
    /** Comma separated list of fields to order results by. Default: `id`. Possible values: `date_created,assignment__start_date`. */
    orderBy?: string;
    profileId: string;
    /** `ID` of the object next page should start after. Cannot be used in conjunction with `ending_before`. */
    startingAfter?: string;
};
export type Assignment = {
    applicantAvatarPhotoIds: string;
    applicantsNewMessagesCount: string;
    applicationsCount: string;
    boostedCount?: number;
    boostedDatetime?: string | null;
    endDate: string;
    feedback: string;
    feedbackRequest: string;
    id: string;
    invitationsCount: string;
    isApproximateDates?: boolean;
    isAutomaticallyPaused: boolean;
    isDeleted?: boolean;
    isFeedbackProvided: string;
    isPrivate?: boolean;
    isReviewProvided: string;
    isReviewing?: boolean;
    lastMessage: string;
    listing: string;
    listingId: number;
    pendingApplicationsCount: string;
    profile: string;
    profileId?: number | null;
    recentApplications: string;
    recentInvitations: string;
    review: string;
    reviewRequest: string;
    reviewWindowEndDate: string;
    sitterNewMessagesCount: string;
    startDate: string;
    welcomeGuideStatus: string;
};
export type WelcomeGuideDetails = {
    assignmentId: number;
    changed: string | null;
    id: string;
    lastSeen: string | null;
    publishedAt?: string | null;
    requested: boolean;
    welcomeMessage?: string | null;
};
export type CurrentAssignment = {
    applicantsNewMessagesCount: string;
    endDate: string;
    id: string;
    isApproximateDates?: boolean;
    isAutomaticallyPaused: boolean;
    isDeleted?: boolean;
    isPrivate?: boolean;
    isReviewing?: boolean;
    lastMessage: string;
    listing: string;
    listingId: number;
    profile: string;
    profileId?: number | null;
    sitterNewMessagesCount: string;
    startDate: string;
    welcomeGuideStatus: string;
};
export type PastAssignment = {
    applicantsNewMessagesCount: string;
    endDate: string;
    feedback: string;
    feedbackRequest: string;
    id: string;
    isApproximateDates?: boolean;
    isAutomaticallyPaused: boolean;
    isDeleted?: boolean;
    isFeedbackProvided: string;
    isPrivate?: boolean;
    isReviewProvided: string;
    isReviewing?: boolean;
    lastMessage: string;
    listing: string;
    listingId: number;
    profile: string;
    profileId?: number | null;
    review: string;
    reviewRequest: string;
    reviewWindowEndDate: string;
    sitterNewMessagesCount: string;
    startDate: string;
    welcomeGuideStatus: string;
};
export type UpcomingAssignment = {
    applicantsNewMessagesCount: string;
    endDate: string;
    id: string;
    isApproximateDates?: boolean;
    isAutomaticallyPaused: boolean;
    isDeleted?: boolean;
    isPrivate?: boolean;
    isReviewing?: boolean;
    lastMessage: string;
    listing: string;
    listingId: number;
    profile: string;
    profileId?: number | null;
    sitterNewMessagesCount: string;
    startDate: string;
    welcomeGuideStatus: string;
};
export type ConfirmedAssignments = {
    current: CurrentAssignment[];
    past: PastAssignment[];
    upcoming: UpcomingAssignment[];
};
export type PaginatedResponse6 = {
    count: number;
    hasMore: boolean;
    results: PastAssignment[];
};
export type PaginatedResponse5 = {
    count: number;
    hasMore: boolean;
    results: UpcomingAssignment[];
};
export type PaginatedResponse8 = {
    count: number;
    hasMore: boolean;
    results: Assignment[];
};
export type OpenAssignment = {
    applicantAvatarPhotoIds: string;
    applicantsNewMessagesCount: string;
    applicationsCount: string;
    boostedCount: number;
    boostedDatetime: string | null;
    endDate: string;
    id: string;
    invitationsCount: string;
    isApproximateDates?: boolean;
    isAutomaticallyPaused: boolean;
    isDeleted?: boolean;
    isPrivate?: boolean;
    isReviewing?: boolean;
    listing: string;
    listingId: number;
    pendingApplicationsCount: string;
    profile: string;
    profileId?: number | null;
    recentApplications: string;
    recentInvitations: string;
    startDate: string;
};
export type PaginatedResponse9 = {
    count: number;
    hasMore: boolean;
    results: OpenAssignment[];
};
export type PaginatedResponse10 = {
    count: number;
    hasMore: boolean;
    results: Assignment[];
};
export type SentByEnum = 'me' | 'contact';
export type SubTypeEnum = 'text' | 'json' | 'url';
export type BlankEnum = '';
export type NullEnum = null;
export type Type1D3Enum =
    | 'message'
    | 'message-photo'
    | 'message-video'
    | 'assignment-application'
    | 'assignment-datechange'
    | 'datechange-declined'
    | 'datechange-accepted'
    | 'assignment-datedeleted'
    | 'assignment-invitation'
    | 'assignment-confirmed'
    | 'invitation-declined'
    | 'invitation-cancelled'
    | 'invitation-acknowledged'
    | 'application-declined'
    | 'application-confirmed'
    | 'application-cancelled'
    | 'sitconfirmation-declined'
    | 'assignment-unconfirmed';
export type OwnerMessage = {
    body?: string;
    id: string;
    isRead?: boolean;
    listingId: number;
    photo: string;
    photoId?: number;
    profileId: number;
    recipientId: number;
    sendDate: string | null;
    sendDateUtc: string;
    senderId: number;
    sentBy: SentByEnum;
    subType?: (SubTypeEnum | BlankEnum | NullEnum) | null;
    type?: (Type1D3Enum | BlankEnum | NullEnum) | null;
    videoId?: number;
};
export type PaginatedResponse11 = {
    count: number;
    hasMore: boolean;
    results: OwnerMessage[];
};
export type CreateOwnerFavourite = {
    id: number;
};
export type ChildrenPreferencesEnum =
    | '0-3 years'
    | '4-7 years'
    | '8-12 years'
    | '13-17 years'
    | '18+ years';
export type FamilyPreferencesEnum = 'single' | 'couple' | 'family';
export type FamilyTypeEnum = 'single' | 'couple' | 'family';
export type HomeTypeEnum = 'house' | 'apartment';
export type MinutesPetCanBeLeftAloneEnum = 0 | 120 | 240 | 360 | 480;
export type WifiAvailableEnum = 'high speed' | 'basic' | 'none';
export type Listing = {
    carIncluded?: boolean | null;
    carRequired?: boolean | null;
    childrenPreferences?: (ChildrenPreferencesEnum | NullEnum)[] | null;
    disabledAccess?: boolean | null;
    familyFriendly?: boolean | null;
    familyPreferences?: (FamilyPreferencesEnum | NullEnum)[] | null;
    familyType?: (FamilyTypeEnum | BlankEnum | NullEnum) | null;
    features?: string;
    feedbackAverageScore: string;
    homeType?: (HomeTypeEnum | BlankEnum | NullEnum) | null;
    id: string;
    inReview: string;
    introduction?: string;
    isAccessibleByPublicTransport?: boolean | null;
    isApproved: boolean;
    isIncomplete: string;
    localAttractions?: any[];
    location: string;
    minutesPetCanBeLeftAlone?: (MinutesPetCanBeLeftAloneEnum | NullEnum) | null;
    noPets?: boolean | null;
    pets: string;
    petsWelcome?: boolean | null;
    photos: string;
    placeId?: number | null;
    publishedDate: string;
    responsibilities?: string;
    selectedAnimals?: number;
    suitableForHomeWorking?: boolean | null;
    title?: string;
    user: string;
    wifiAvailable?: (WifiAvailableEnum | BlankEnum | NullEnum) | null;
    wishToMeetInPerson?: number | null;
    wishToVideoCall?: number | null;
};
export type Address = {
    city: string;
    country: string;
    countryId: number;
    dateCreated: string;
    dateModified: string;
    houseNumberOrName?: string;
    id: string;
    listingId: number;
    stateOrCounty?: string;
    streetName: string;
    zipOrPostcode?: string;
};
export type BedTypesEnum =
    | 'king'
    | 'queen'
    | 'double'
    | 'single'
    | 'sofa_bed'
    | 'child_bed'
    | 'crib';
export type HeatingTypesEnum = 'central' | 'log_burner' | 'fireplace';
export type ParkingTypeEnum =
    | 'free_off_street'
    | 'free_on_street'
    | 'paid_off_street'
    | 'paid_on_street';
export type StorageTypesEnum = 'wardrobe' | 'drawers';
export type StoveTypeEnum = 'gas' | 'electric' | 'induction' | 'other';
export type TvTypesEnum = 'streaming_services' | 'cable' | 'dvd' | 'standard_tv';
export type WifiTypeEnum = 'basic' | 'high_speed';
export type WorkspaceTypesEnum = 'office' | 'desk' | 'worktop';
export type Amenities = {
    bedTypes?: BedTypesEnum[];
    dateCreated: string;
    dateModified: string;
    hasAirConditioning?: boolean | null;
    hasBalcony?: boolean | null;
    hasBarbecue?: boolean | null;
    hasBath?: boolean | null;
    hasBathroomEssentials?: boolean | null;
    hasBedding?: boolean | null;
    hasBikeAccess?: boolean | null;
    hasBlender?: boolean | null;
    hasBurglarAlarm?: boolean | null;
    hasCarAccess?: boolean | null;
    hasCarbonMonoxideAlarm?: boolean | null;
    hasCoffeeMaker?: boolean | null;
    hasCommunalGardens?: boolean | null;
    hasCookingBasics?: boolean | null;
    hasDiningArea?: boolean | null;
    hasDishwasher?: boolean | null;
    hasDryer?: boolean | null;
    hasFireAlarm?: boolean | null;
    hasFireExtinguisher?: boolean | null;
    hasFirstAidKit?: boolean | null;
    hasFreezer?: boolean | null;
    hasFridge?: boolean | null;
    hasGarden?: boolean | null;
    hasHairdryer?: boolean | null;
    hasHotTub?: boolean | null;
    hasIron?: boolean | null;
    hasKettle?: boolean | null;
    hasMicrowave?: boolean | null;
    hasOutdoorDiningArea?: boolean | null;
    hasOven?: boolean | null;
    hasPatio?: boolean | null;
    hasPool?: boolean | null;
    hasScenicViews?: boolean | null;
    hasSecurityCameras?: boolean | null;
    hasShampoo?: boolean | null;
    hasShower?: boolean | null;
    hasSofa?: boolean | null;
    hasStereo?: boolean | null;
    hasTerrace?: boolean | null;
    hasToaster?: boolean | null;
    hasVacuum?: boolean | null;
    hasWasher?: boolean | null;
    heatingTypes?: HeatingTypesEnum[];
    id: string;
    listingId: number;
    parkingType?: ParkingTypeEnum | BlankEnum;
    storageTypes?: StorageTypesEnum[];
    stoveType?: StoveTypeEnum | BlankEnum;
    tvTypes?: TvTypesEnum[];
    wifiType?: WifiTypeEnum | BlankEnum;
    workspaceTypes?: WorkspaceTypesEnum[];
};
export type Application = {
    assignment: string;
    assignmentId: number;
    dateCreated: string;
    hasAssignmentDatesChanged: boolean;
    hasOwnerCancelled: boolean;
    hasOwnerConfirmed?: boolean;
    hasOwnerDeclined?: boolean;
    hasSitterCancelled: boolean;
    hasSitterConfirmed: boolean;
    hasSitterDeclined: boolean;
    id: string;
    isConfirmed: boolean;
    isInvitationAcknowledged: boolean;
    isInvited?: boolean;
    isShortlisted?: boolean;
    lastMessage: string;
    listing: string;
    listingId: number;
    newMessagesCount: string;
    profile: string;
    profileId: number;
};
export type PaginatedResponse12 = {
    count: number;
    hasMore: boolean;
    results: Application[];
};
export type Invitation = {
    assignment: string;
    assignmentId: number;
    dateCreated: string;
    hasAssignmentDatesChanged: boolean;
    hasOwnerCancelled?: boolean;
    hasOwnerConfirmed: boolean;
    hasOwnerDeclined: boolean;
    hasSitterCancelled: boolean;
    hasSitterConfirmed: boolean;
    hasSitterDeclined: boolean;
    id: string;
    isConfirmed: boolean;
    isInvitationAcknowledged: boolean;
    isInvited?: boolean;
    isShortlisted?: boolean;
    lastMessage: string;
    listing: string;
    listingId: number;
    newMessagesCount: string;
    profile: string;
    profileId: number;
};
export type PaginatedResponse13 = {
    count: number;
    hasMore: boolean;
    results: Invitation[];
};
export type Feedback = {
    accuracyOfListing: number;
    assignmentId: number;
    cleanliness: number;
    communication: number;
    description: string;
    hospitality: number;
    id: string;
    isPublished: boolean;
    petBehaviour: number;
    publishedAt: string;
    recommended: number;
    reply?: string | null;
    replySentAt?: string | null;
    sentAt: string;
    version: number;
};
export type FeedbackRequest = {
    assignment: string;
    assignmentId: number;
    completedDate?: string | null;
    id: string;
    message?: string | null;
    rejectedDate?: string | null;
    sentAt: string;
};
export type Review = {
    assignmentId: number;
    description?: string | null;
    id: string;
    isPublished: boolean;
    organised: number;
    overallScore?: number;
    petCare?: number;
    publishedAt: string;
    reliable: number;
    reply: string | null;
    replySentAt: string | null;
    selfSufficient: number;
    sentAt: string;
    tidy: number;
};
export type BatchMessage = {
    body?: string;
    id: string;
    isRead?: boolean;
    listingId: number;
    photo: string;
    photoId?: number;
    profileId: number;
    recipientId: number;
    sendDate: string | null;
    sendDateUtc: string;
    senderId: number;
    sentBy: SentByEnum;
    subType?: (SubTypeEnum | BlankEnum | NullEnum) | null;
    type?: (Type1D3Enum | BlankEnum | NullEnum) | null;
    videoId?: number;
};
export type FutureAssignment = {
    applicantAvatarPhotoIds: string;
    endDate: string;
    id: string;
    isApproximateDates?: boolean;
    isAutomaticallyPaused: boolean;
    isDeleted?: boolean;
    isPrivate?: boolean;
    isReviewing?: boolean;
    listing: string;
    listingId: number;
    pendingApplicationsCount: string;
    profile: string;
    profileId?: number | null;
    startDate: string;
    welcomeGuideStatus: string;
};
export type OpenAssignmentWithSitterStatus = {
    endDate: string;
    id: string;
    isApproximateDates: boolean;
    isAutomaticallyPaused: boolean;
    isPrivate: boolean;
    isReviewing: boolean;
    listingId: number | null;
    pendingApplicationsCount: string;
    startDate: string;
    status: string;
};
export type DailyActivityMinutesEnum = 30 | 60 | 120 | 180;
export type SizeEnum = 'XS' | 'S' | 'M' | 'L' | 'XL';
export type Pet = {
    animal: string;
    animalId: number;
    breed: string;
    breedId?: number | null;
    breeds?: number[];
    cleaning?: string;
    dailyActivityMinutes?: (DailyActivityMinutesEnum | NullEnum) | null;
    description?: string | null;
    exerciseAndPlay?: string;
    extraInformation?: string | null;
    feedingInstructions?: string;
    generalInformation?: string;
    healthInformation?: string;
    houseRules?: string;
    id: string;
    likesDislikesInformation?: string;
    listingId: number;
    name?: string | null;
    otherInformation?: string;
    personality?: any[];
    personalityExtra?: string;
    personalityOther?: string;
    photos: string;
    requiresRegularMedication?: boolean | null;
    size?: (SizeEnum | NullEnum) | null;
    toiletAndGrooming?: string;
    yearOfBirth?: number | null;
};
export type PetPhoto = {
    description?: string | null;
    id: string;
    publicId?: string;
};
export type ListingPhoto = {
    description?: string | null;
    id: string;
    publicId?: string;
};
export type CategoryEnum = 'shop' | 'restaurant_or_pub' | 'cafe' | 'walk' | 'attraction';
export type PlaceOfInterest = {
    category?: CategoryEnum | BlankEnum;
    dateCreated: string;
    id: string;
    listingId: number;
    name: string;
    petFriendly?: boolean;
    phoneNumber?: string;
    photos: string;
    userId: number;
    website?: string;
    whyRecommended?: string;
};
export type PlaceOfInterestPhoto = {
    description?: string | null;
    id: string;
    publicId?: string;
};
export type PublishListing = {
    publishedDate: string;
};
export type SubmissionReviewListing = {
    status: string;
};
export type WelcomeGuideEmergencyDetails = {
    dentistDetails?: {
        [key: string]: any;
    };
    hospitalDetails?: {
        [key: string]: any;
    };
    nominatedPeople?: any[];
    pharmacyDetails?: {
        [key: string]: any;
    };
    policeContactDetails?: string;
    repairsContactDetails?: string;
};
export type WelcomeGuideHomeDetails = {
    address?: string;
    audioDetails?: string;
    cleanerDetails?: string;
    cleaningInstructions?: string;
    contacts?: any[];
    desktopAvailable?: boolean | null;
    desktopInstructions?: string;
    doorsAndWindowsInstructions?: string;
    electricityInstructions?: string;
    fireAlarmInstructions?: string;
    firstAidKitInstructions?: string;
    garbageAndRecyclingInstructions?: string;
    gardenExtraInformation?: string;
    gardenerDetails?: string;
    gardeningInstructions?: string;
    hasCleaner?: boolean | null;
    hasFirstAidKit?: boolean | null;
    hasGardener?: boolean | null;
    hasHomeOffice?: boolean | null;
    heatingInstructions?: string;
    hifiExtraInformation?: string;
    homeExtraInformation?: string;
    houseRules?: string;
    howToGetThere?: string;
    indoorPlantsInstructions?: string;
    kitchenAppliances?: any[];
    kitchenAppliancesOther?: string;
    kitchenSpecialInstructions?: string;
    outdoorSpaceDetails?: any[];
    phoneDetails?: string;
    phoneSignal?: string;
    postDetails?: string;
    roomsExtraFeatures?: any[];
    roomsInstructions?: string;
    roomsSpecialInstructions?: string;
    securityAlarmInstructions?: string;
    securityExtraInformation?: string;
    spareKeysContacts?: {
        [key: string]: any;
    };
    toysAndActivities?: string;
    tvDetails?: string;
    waterInstructions?: string;
    wifiDetails?: string;
    wifiSpeed?: string;
};
export type WelcomeGuideNeighbourhoodDetails = {
    neighbourhoodExtraInformation?: string;
};
export type WelcomeGuidePetsDetails = {
    veterinaryInformation?: {
        [key: string]: any;
    };
};
export type WelcomeGuideTransportDetails = {
    busDetails?: string;
    carDetails?: string;
    carHireDetails?: string;
    carInsuranceDetails?: string;
    carNeeded?: boolean | null;
    carParking?: string;
    taxiDetails?: string;
    trainDetails?: string;
    useOfCar?: boolean | null;
};
export type WelcomeGuide = {
    dateModified?: string | null;
    emergencyDetails?: WelcomeGuideEmergencyDetails;
    homeDetails?: WelcomeGuideHomeDetails;
    id: string;
    listingId: number;
    neighbourhoodDetails?: WelcomeGuideNeighbourhoodDetails;
    petsDetails?: WelcomeGuidePetsDetails;
    transportDetails?: WelcomeGuideTransportDetails;
};
export type WelcomeGuidePhoto = {
    description?: string | null;
    id: string;
    publicId?: string;
};
export type PaginatedResponse14 = {
    count: number;
    hasMore: boolean;
    results: Application[];
};
export type PaginatedResponse15 = {
    count: number;
    hasMore: boolean;
    results: PastAssignment[];
};
export type PaginatedResponse16 = {
    count: number;
    hasMore: boolean;
    results: Application[];
};
export const {
    useGetOwnerAssignmentQuery,
    useGetOwnerAssignmentWelcomeGuideDetailsQuery,
    useCreateOwnerAssignmentWelcomeGuideDetailsMutation,
    useUpdateOwnerAssignmentWelcomeGuideDetailsMutation,
    useGetOwnerAssignmentsConfirmedQuery,
    useGetOwnerAssignmentsConfirmedCurrentQuery,
    useGetOwnerAssignmentsConfirmedPastQuery,
    useGetOwnerAssignmentsConfirmedUpcomingQuery,
    useGetOwnerAssignmentsDeletedQuery,
    useGetOwnerAssignmentsOpenQuery,
    useGetOwnerAssignmentsUnconfirmedPastQuery,
    useGetOwnerConversationQuery,
    useCreateOwnerConversationMutation,
    useUpdateOwnerConversationMutation,
    useCreateOwnerConversationsBatchMutation,
    useUpdateOwnerConversationsBatchMutation,
    useGetOwnerFavouritesQuery,
    useCreateOwnerFavouritesMutation,
    useDeleteOwnerFavouriteMutation,
    useGetOwnerListingsQuery,
    useCreateOwnerListingsMutation,
    useGetOwnerListingQuery,
    useUpdateOwnerListingMutation,
    useGetOwnerListingAddressQuery,
    useCreateOwnerListingAddressMutation,
    useUpdateOwnerListingAddressMutation,
    useGetOwnerListingAmenitiesQuery,
    useCreateOwnerListingAmenitiesMutation,
    useUpdateOwnerListingAmenitiesMutation,
    useGetOwnerListingAssignmentApplicationsQuery,
    useGetOwnerListingAssignmentApplicationQuery,
    useUpdateOwnerListingAssignmentApplicationMutation,
    useGetOwnerListingAssignmentInvitationsQuery,
    useCreateOwnerListingAssignmentInvitationsMutation,
    useGetOwnerListingAssignmentInvitationQuery,
    useUpdateOwnerListingAssignmentInvitationMutation,
    useGetOwnerListingAssignmentsConfirmedCurrentQuery,
    useGetOwnerListingAssignmentsConfirmedPastQuery,
    useUpdateOwnerListingAssignmentsConfirmedPastFeedbackMutation,
    useCreateOwnerListingAssignmentsConfirmedPastFeedbackRequestMutation,
    useCreateOwnerListingAssignmentsConfirmedPastReviewMutation,
    useGetOwnerListingAssignmentsConfirmedUpcomingQuery,
    useUpdateOwnerListingAssignmentsConfirmedUpcomingMutation,
    useCreateOwnerListingAssignmentsConfirmedUpcomingBatchMessagesMutation,
    useGetOwnerListingAssignmentsFutureQuery,
    useGetOwnerListingAssignmentsOpenQuery,
    useCreateOwnerListingAssignmentsOpenMutation,
    useGetOwnerListingAssignmentOpenQuery,
    useUpdateOwnerListingAssignmentsOpenMutation,
    useDeleteOwnerListingAssignmentsOpenMutation,
    useCreateOwnerListingAssignmentsOpenBoostMutation,
    useGetOwnerListingAssignmentsOpenSitterQuery,
    useCreateOwnerListingInvitationsBatchMutation,
    useGetOwnerListingPetsQuery,
    useCreateOwnerListingPetsMutation,
    useGetOwnerListingPetQuery,
    useUpdateOwnerListingPetMutation,
    useDeleteOwnerListingPetMutation,
    useGetOwnerListingPetPhotosQuery,
    useCreateOwnerListingPetPhotoMutation,
    useDeleteOwnerListingPetPhotoMutation,
    useGetOwnerListingPhotosQuery,
    useCreateOwnerListingPhotoMutation,
    useDeleteOwnerListingPhotoMutation,
    useGetOwnerListingPlacesOfInterestQuery,
    useCreateOwnerListingPlacesOfInterestMutation,
    useGetOwnerListingPlaceOfInterestQuery,
    useUpdateOwnerListingPlacesOfInterestMutation,
    useDeleteOwnerListingPlacesOfInterestMutation,
    useGetOwnerListingPlacesOfInterestPhotosQuery,
    useCreateOwnerListingPlacesOfInterestPhotoMutation,
    useDeleteOwnerListingPlacesOfInterestPhotoMutation,
    useCreateOwnerListingPublishMutation,
    useCreateOwnerListingSubmissionReviewMutation,
    useGetOwnerListingWelcomeGuideQuery,
    useCreateOwnerListingWelcomeGuideMutation,
    useUpdateOwnerListingWelcomeGuideMutation,
    useGetOwnerListingWelcomeGuidePhotosQuery,
    useCreateOwnerListingWelcomeGuidePhotoMutation,
    useDeleteOwnerListingWelcomeGuidePhotoMutation,
    useGetOwnerSitterApplicationsQuery,
    useGetOwnerSitterAssignmentsQuery,
    useGetOwnerSitterInvitationsQuery,
} = injectedRtkApi;
